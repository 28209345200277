@tailwind base;
@tailwind components;
@tailwind utilities;

/* apparently without this there's always a scroll, idk why */
body {
  overflow-x: hidden;
}

* {
  scroll-behavior: smooth;
}